import { createRouter, createWebHistory } from 'vue-router'
import Anasayfa from '../views/Home.vue'

import NotFound from '../views/NotFound.vue'


// import Contactphp from '../views/Contact.php'

const routes = [
  {
    path: '/',
    name: 'home',
    component: Anasayfa
  },
  {
    path: '/hakkimizda',
    name: 'hakkimizda',
    // component: Hakkımızda
    component: () => import('../views/Hakkimizda.vue') //linke tıklandığında sayfayı yükler. Böyle yapmazsak ilk açıldığında direk sayfayı yükler.
  },
  {
    path: '/projemiz',
    name: 'projemiz',
    component: () => import('../views/Projemiz.vue') //linke tıklandığında sayfayı yükler. Böyle yapmazsak ilk açıldığında direk sayfayı yükler.

    // component:Projemiz
  },
  {
    path: '/galeri',
    name: 'galeri',
    component: () => import('../views/Galeri.vue') //linke tıklandığında sayfayı yükler. Böyle yapmazsak ilk açıldığında direk sayfayı yükler.

    // component:Galeri
  },
  // {
  //   path: '/contact.php',
  //   name: 'contact',
  //   component:Contactphp
  // },
  {
    path: '/iletisim',
    name: 'iletisim',
    component: () => import('../views/İletisim.vue')
    // component:İletişim
  },
  {
    // path: '/jobs/:id',
    // name: 'jobdetails',
    // component:JobDetails,
    // props:true
  },
  //redirect
  {
    // path: '/all-jobs',
    // redirect:'/jobs'
  },
  //catchall 404
  {
    path: '/:catchAll(.*)',
    name:'notfound',
    component: NotFound
  }
]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
