<template>
<div class="container-fluid mt-5" >
  <div id="carouselExampleAutoplaying" class="carousel slide" data-bs-ride="carousel">
  <div class="carousel-inner">
    <div class="carousel-item active">
      <img src="../assets/slider.png" class="d-block w-100" alt="...">
    </div>
    <div class="carousel-item">
      <img src="../assets/slider2.png" class="d-block w-100" alt="...">
    </div>
  </div>
  <button class="carousel-control-prev" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="prev">
    <span class="carousel-control-prev-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Previous</span>
  </button>
  <button class="carousel-control-next" type="button" data-bs-target="#carouselExampleAutoplaying" data-bs-slide="next">
    <span class="carousel-control-next-icon" aria-hidden="true"></span>
    <span class="visually-hidden">Next</span>
  </button>
</div>

  <!-- <img src="../assets/slider.png" class="img-fluid" alt="..."> -->

  <div class="row justify-content-md-center" >

        <div class="card m-3 col-lg-5" style="background-color: rgb(46, 46, 46); border-radius: 30px;border: 1; padding: 50px;">
        <div class="card-body" >
          <h3 style="font-weight: bold; color: rgb(215, 214, 214)">
        Jeotermal Enerji
        </h3>
        <p style="text-align: justify; font-size: 1.2em; color: rgb(215, 214, 214); margin-top: 20px;">
        Firmamız, jeotermal kuru kaya ve âtıl kuyu sıcaklığını kullanarak
        deniz suyunu tuzdan arındırarak, buhar, elektrik hidrojen ve içme suyu
        üretiyoruz.<br /><br />
        </p>
        </div>
        <img style="top:0; margin-top: 0;" src="../assets/jeotermalornek.png" class="card-img-bottom" alt="...">
        </div>


        <div class="card m-3  col-lg-5" style="background-color: rgb(239, 201, 6); border-radius: 30px;border: 1; padding: 50px;">
        <div class="card-body" >
          <h3 style="font-weight: bold; color: black">Termik Güneş Enerjisi</h3>
          <p style="text-align: justify; color: black; font-size: 1.2em; ">
              Firmamız güneş parabolik oluk kolektörlerle buhar üretimi ve bununla
              enerji, hidrojen, oksijen ve tatlı su üretimi yapmaktadır. <br />
            </p>
        </div>
        <img style="top:0; margin-top: 0;" src="../assets/termikornek.png" class="card-img-bottom" alt="...">
        </div>

    <p style="font-size: 2em;">
--------------------------------------------------------
</p>
    <div class="card m-3 col-lg-5"  >
      <img src="../assets/elektroliz.jpeg" class="card-img-top"  alt="elektroliz">
      <div class="card-body ">
          <h5 class="card-title">Elektroliz</h5>
          <p class="card-text">Türbinden gelen saf suyun elektroliz edilerek <b>hidrojen</b> ve <b>oksijene</b> ayrılması</p>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal">
            Detaylı Bilgi
          </button>
          <div class="modal fade" id="exampleModal" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Elektroliz (Detaylı Bilgi)</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <p style="text-align:justify" class="card-title">Suyun hidrojene dönüştürülmesi prosesidir. <br><br>
Dünya yüzeyinin %70,1'ini kaplayan su, çevrede en bol bulunan moleküldür. Moleküler olarak su, bir 
oksijen atomuna (O) bağlı iki hidrojen atomu (H) şeklindedir. H ve O atomları arasındaki bağlar 
elektrikle kolayca çözülebilir. Elektrik akımına maruz kalınca su molekülü kohezyonunu kaybeder. 
H2O, bir hidroksit iyonu (OH)- ve bir proton H+ olur. <br><br>
Yan yana yerleştirilen protonlar birbirleriyle reaksiyona girerek dihidrojen (H2) verir. Bu işlemin en 
büyük avantajlarından biri çok kolay gerçekleşmesidir. Elektroliz işlemi kolaylıkla saf hidrojen elde 
etmeyi mümkün kılar.<br><br>
Proses adımı ne olursa olsun, tek bir zararlı gaz salınmaz. Bu nedenle, yeşil hidrojen üretmenin
şimdilik tek yolu budur.</p>
                
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
              </div>
            </div>
          </div>
          </div>
      </div>
    </div>

    <div class="card m-3 col-lg-5">
      <img src="../assets/esanjor.jpeg"  class="card-img-top"   alt="elektroliz">
      <div class="card-body ">
          <h5 class="card-title">Isı Eşanjörü</h5>
          <p class="card-text">Arge çalışmalarını yürüttüğümüz suyun buhar haline geçişini sağlayan ısı eşanjörümüz</p>
          <button type="button" class="btn btn-primary" data-bs-toggle="modal" data-bs-target="#exampleModal2">
            Detaylı Bilgi
          </button>
          <div class="modal fade" id="exampleModal2" tabindex="-1" aria-labelledby="exampleModalLabel" aria-hidden="true">
          <div class="modal-dialog">
            <div class="modal-content">
              <div class="modal-header">
                <h1 class="modal-title fs-5" id="exampleModalLabel">Isı Eşanjörü (Detaylı Bilgi)</h1>
                <button type="button" class="btn-close" data-bs-dismiss="modal" aria-label="Close"></button>
              </div>
              <div class="modal-body">
                <h5 class="card-title">Isı Eşanjörümüzün Fransa'da patenti alınmıştır.</h5>
                <img src="../assets/patent.png"  class="card-img-top"   alt="elektroliz">
              </div>
              <div class="modal-footer">
                <button type="button" class="btn btn-secondary" data-bs-dismiss="modal">Kapat</button>
              </div>
            </div>
          </div>
          </div>
      </div>
      
    </div>

    

    
  </div>
  <div class="row justify-content-sm-center mb-4">
  <div class="col-fluid col-xl-8 mt-4" >
    <h5 style="background-color:rgb(7, 109, 150);color: white;border-radius: 20px 20px 0 0 ; padding: 10px; ">Proje Tanıtım Videosu</h5>
    
    <video  src="../assets/tanitim.mp4" class="object-fit-scale w-100" controls autoplay loop></video>
  </div>
  </div>

  <div class="row justify-content-md-center m-4">
      <H3 style="background-color: rgb(46, 46, 46); color:white; padding: 20px; border-radius: 30px; margin-top:2em; margin-bottom:2em;font-size: 2em;">
    ÜRÜN ÇIKTILARI
    </H3>
<div class="col-sm m-2" >
  <div class="card border-0" >
    <img src="../assets/elektrik.png"  class="card-img-top" alt="...">
    <div class="card-body">
      <p class="card-title">Günlük 90-120 MWh güç üretimi 
      </p>
    </div>
  </div>
</div>
<div class="col-sm m-2 ">
  <div class="card border-0">
    <img src="../assets/h2o2.jpg"  class="card-img-top" alt="...">
    <div class="card-body">
      <p class="card-title" >Günlük 24 ton suyun elektrolizi ile 2640 kg Hidrojen ve 21360 kg oksijen üretilmesi
</p>
    </div>
  </div>
</div>
<div class="col-sm m-2">
  <div class="card border-0" >
    <img src="../assets/su.jpg" class="card-img-top" alt="...">
    <div class="card-body">
      <p class="card-title">Elde edilen saf suya mineral eklenerek içme suyu elde edilmesi
</p>
    </div>
  </div>
</div>
<div class="col-sm m-2" >
  <div class="card border-0">
    <img src="../assets/ev.jpg" class="card-img-top" alt="...">
    <div class="card-body">
      <p class="card-title">Üretilen güç ile günlük 5000-7500 hanenin elektriğinin karşılanması
</p>
    </div>
  </div>
</div>
</div>
</div>



</template>

<script>


// import Projemiz from './Projemiz.vue'
// import Iletisim from './İletisim.vue'



export default {
name: "HomeView",
components: {

  // Projemiz,
  // Iletisim
},
methods: {

},



}


</script>

<style>
</style>
