<template>



<nav class="navbar navbar-expand-md  bg-light   fixed-top " >

  <div class="container-fluid">
    <div style="height:50px">
    <img src="./assets/logo1.png" alt="Anasayfa" width="192" height="50" class="position-absolute align-text-top">
  </div>
      
     
 
    <button class="navbar-toggler" type="button" data-bs-toggle="collapse" data-bs-target="#navbarCollapse" aria-controls="navbarCollapse" aria-expanded="false" aria-label="Toggle navigation">
      <span class="navbar-toggler-icon"> </span>
    </button>
    <div class="collapse navbar-collapse justify-content-center align-text-center" id="navbarCollapse">
      <ul class="navbar-nav  mb-2 mb-md-0 ">
        <li class="nav-item mt-2">
          <router-link to="/">Anasayfa</router-link>
        </li>
        <li class="nav-item mt-2">
          <router-link to="/hakkimizda">Hakkımızda</router-link>
        </li> 
        <li class="nav-item mt-2">
          <router-link to="/projemiz">Projemiz</router-link>
        </li>
        <li class="nav-item mt-2">
          <router-link to="/galeri">Galeri</router-link>
        </li>
        <li class="nav-item mt-2">
          <router-link to="/iletisim">Bize Ulaşın</router-link>
        </li>

      </ul>

    </div>
  </div>
</nav>
 
<!-- <div style="background-color: red;">
  <router-link to="{name: 'hakkimizda'}">Hakkımızda</router-link> |
    <router-link to="{name: 'projemiz'}">Projemiz</router-link>|
    <router-link to="{name: 'iletisim'}">Bize Ulaşın</router-link>
</div> -->



  <router-view />

  <div class="row justify-content-sm-center">
    <div class="col-fluid col-lg-2">
      <a href="tel:+905422910165">
        <span class="material-symbols-outlined"> call </span>
        <p>+90 542 291 01 65</p>
      </a>
    </div>
    <div class="col-fluid col-lg-2">
      <a href="mailto:atosenerji@gmail.com">
        <span class="material-symbols-outlined"> mail </span>
        <p>atosenerji@gmail.com</p>
      </a>
    </div>
    <div class="col-fluid col-lg-2">
      <a href="https://goo.gl/maps/E6AfiUap2uxWbAy66" target="_blank">
        <span class="material-symbols-outlined"> home </span>
        <p>Teknopark Yerleşkesi/Çanakkale</p>
      </a>
    </div>
    <p>
      Copyright© 2024
      <a style="color: rgb(12, 123, 167)" href="#">Atos Enerji</a>. Tüm Hakları
      Saklıdır.
    </p>
    
  </div>
</template>
<script>

export default {
  methods: {
    // redirect()
    // {
    //   this.$router.push({name:'home'})
    // },
    // back()
    // {
    //     this.$router.go(-1)
    // },
    // forward()
    // {
    //   this.$router.go(1)
    // }
  },
};

</script>

<style>

#app {
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  text-align: center;
  color: #2c3e50;
}
h3 {
  color: rgb(12, 123, 167);
}
h1 {
  color: rgb(12, 123, 167);
}

.navbar{
  color: white;
}
a {
  color: #2c3e50;
  text-decoration: none;
  padding: 10px;
  border-radius: 5px;
}

nav a:hover {
  color: white;
  background-color: rgb(12, 123, 167);
}
button {
  margin: 0 10px;
  padding: 10px;
  border: none;
  border-radius: 4px;
}
.footer {
  margin-bottom: 0;
  text-align: center;
  width: 100%;
  font-size: 1em;
  font-weight: bold;
}

.form {
  background-color: rgb(7, 109, 150);
  text-align: left;
  padding: 40px;
  border-radius: 20px;
}
.page {
  margin-top: 20px;
}
.baslik {
  color: black;
  background-color: white;
  padding: 10px;
  border-radius: 20px 20px 0 0;
}
</style>
